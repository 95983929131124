@import "./partials/mixins";

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
}

//=== navigator style =====//
.navigator-main-container {
  @include custom-box-shadow-with-border;
}
html {
  font-size: 16px;
}
.navigator-container {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  place-items: center;
  .MuiToolbar-root {
    width: 100%;
  }
}

.navigator-container-float {
  height: 80px;
  transition: all 0.4s ease-in;
}
.navigator-container-hide {
  height: 0px;
  transition: all 0.4s ease-in;
}

.logo-container {
  flex-grow: 1;
  svg > path {
    @include soft-box-shadow;
    color: #d32f2f;
  }
}
.link-container {
  flex-grow: 2;
  place-items: center;
  display: flex;
  justify-content: space-between;
}
.appbar-menu-btn {
  display: inline;
  cursor: pointer;
}

.central-box {
  @include custom-box-shadow-with-border;
  max-width: 600px;
  margin: 40px auto auto;
  text-align: center;
}

form {
  .MuiFormHelperText-root {
    font-size: 12px;
    margin-top: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//sidebar
.sidebar-transform-active {
  width: 180px;
}
.sidebar-transform-inactive {
  width: 40px;
}

fieldset {
  border-width: 1px;
}

.custom-link {
  display: inline-block;
  position: relative;
  color: #e76712;
}

.custom-link::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #e76712;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.custom-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.slider-img1 {
  img {
    animation-name: t1;
    animation-duration: 4s;
    animation-fill-mode: forwards;
  }
}
.slider-img2 {
  img {
    animation-name: t2;
    animation-duration: 4s;
    animation-fill-mode: forwards;
  }
}
.slider-img3 {
  img {
    animation-name: t3;
    animation-duration: 4s;
    animation-fill-mode: forwards;
  }
}

@keyframes t1 {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
@keyframes t2 {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
@keyframes t3 {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

fieldset {
  border-width: 0px;
  border-radius: 5px;
  font-size: 18px;
}
.full-btn {
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.5s ease-in;
}
.min-btn {
  width: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.5s ease-in;
}

.show-span {
  display: block;
  transition: all 0.5s ease-in;
}
.hide-span {
  display: none;
  transition: all 0.5s ease-in;
}
.show {
  visibility: visible;
  opacity: 1;
  transition: all 0.5s ease-in;
}
.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in;
}


/* Basic reset */
 /* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

.companies-section {
  padding: 20px;
}

.section-title {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33% - 30px);
  max-width: calc(33% - 30px);
  min-width: 280px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .card {
    flex: 1 1 calc(50% - 30px);
    max-width: calc(50% - 30px);
  }
}

@media (max-width: 480px) {
  .card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}


.photo-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
  padding: 10px;
}

.photo-container {
  overflow: hidden;
  border-radius: 8px;
}

.photo {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.photo:hover {
  transform: scale(1.05);
}

.ReactModal__Overlay{
  z-index: 1200 !important;
}



